
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Line, mixins } from "vue-chartjs";
import { TimeValuePair } from "@/value-objects/time-value-pair";
import TimeSeriesLineChartData from "@/value-objects/time-series-line-chart-data";
import currency from "@/filters/currency";
import { ChartData, ChartOptions, ChartTooltipItem } from "chart.js";

@Component({
    extends: Line,
    mixins: [mixins.reactiveProp],
})
export default class LineChart extends Mixins(mixins.reactiveProp, Line) {
    @Prop({ required: true, type: Object }) chartData!: TimeSeriesLineChartData;
    @Prop({ required: true, type: String }) datasetLabel!: string;
    @Prop({ required: true, type: Number }) yStepSize!: number;

    mounted() {
        // Overwriting base render method with actual data.
        this.renderChart(
            {
                labels: this.chartData.series.map((value: TimeValuePair) => {
                    return value.key;
                }),
                datasets: [
                    {
                        lineTension: 0.2,
                        radius: 4,
                        fill: false,
                        label: this.datasetLabel,
                        data: this.chartData.series.map(
                            (value: TimeValuePair) => {
                                return value.value;
                            }
                        ),
                        borderColor: "#1565C0",
                        pointBackgroundColor: "#1A237E",
                        pointBorderColor: "#1A237E",
                    },
                ],
            },
            {
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            distribution: "series",
                            gridLines: {
                                display: false,
                            },
                            type: "time",
                            time: {
                                unit: "month",
                            },
                            ticks: {
                                fontFamily: "Roboto, sans-serif",
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                fontFamily: "Roboto, sans-serif",
                                stepSize: this.yStepSize,
                                callback: (value: number) => {
                                    if (!this.chartData.isCurrency) {
                                        return value;
                                    }

                                    if (value >= 1000) {
                                        return currency(value / 1000)
                                            .split(" ")
                                            .join("k ");
                                    } else {
                                        return currency(value);
                                    }
                                },
                            },
                        },
                    ],
                },
                tooltips: {
                    enabled: true,
                    mode: "x-axis",
                    callbacks: {
                        title: (tooltipItems) => {
                            return new Date(
                                tooltipItems[0].xLabel as string
                            ).toLocaleString(undefined, {
                                timeZone: this.chartData.timezone,
                                month: "long",
                                year: "numeric",
                            });
                        },
                        label: (
                            tooltipItem: ChartTooltipItem,
                            data: ChartData
                        ) => {
                            let label = "";
                            if (data.datasets?.length) {
                                label = data.datasets[0].label as string;
                            }

                            if (!this.chartData.isCurrency) {
                                return `${label}: ${tooltipItem.value}`;
                            }

                            let value = tooltipItem.value;
                            if (!Number.isNaN(value)) {
                                const val = parseFloat(value as string);
                                value = currency(val);
                            }

                            return `${label}: ${value}`;
                        },
                    },
                },
            } as ChartOptions
        );
    }
}
