




















import { Vue, Component } from "vue-property-decorator";
import { captureSentryException } from "@/plugins/sentry";
import { AxiosError, AxiosResponse } from "axios";
import DashboardChartApi from "@/services/api/dashboard-charts";
import { DASHBOARD_CHART_IDS } from "@/models/dashboard-chart";
import TimeSeriesLineChartData from "@/value-objects/time-series-line-chart-data";
import LineChart from "@/components/LineChart.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { mdiArrowDown, mdiArrowUp } from "@mdi/js";
import ChartSubtitle from "@/components/ChartSubtitle.vue";

@Component({
    components: { ChartSubtitle, LoadingSpinner, LineChart },
})
export default class TotalPaymentsChart extends Vue {
    chartIsLoading: boolean = true;
    lineChartData: TimeSeriesLineChartData | null = null;
    upIcon: string = mdiArrowUp;
    downIcon: string = mdiArrowDown;

    emitErrorNotification(error: Error) {
        captureSentryException(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError) {
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }

    mounted() {
        DashboardChartApi.show(DASHBOARD_CHART_IDS.TOTAL_PAYMENTS)
            .then((response: AxiosResponse) => {
                this.lineChartData = new TimeSeriesLineChartData(
                    response.data.data
                );
                this.chartIsLoading = false;
            })
            .catch(this.handleAxiosError);
    }
}
