






















import { Vue, Component, Prop } from "vue-property-decorator";
import { captureSentryException } from "@/plugins/sentry";
import { AxiosError, AxiosResponse } from "axios";
import DashboardChartApi from "@/services/api/dashboard-charts";
import TimeSeriesLineChartData from "@/value-objects/time-series-line-chart-data";
import LineChart from "@/components/LineChart.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import ChartSubtitle from "@/components/ChartSubtitle.vue";

@Component({
    components: { ChartSubtitle, LoadingSpinner, LineChart },
})
export default class AdminDashboardChart extends Vue {
    @Prop({ required: true }) chartId!: string;
    @Prop({ required: true }) yAxisStep!: number;

    chartIsLoading: boolean = true;
    lineChartData: TimeSeriesLineChartData | null = null;

    emitErrorNotification(error: Error) {
        captureSentryException(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError) {
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }

    mounted() {
        DashboardChartApi.show(this.chartId as string)
            .then((response: AxiosResponse) => {
                this.lineChartData = new TimeSeriesLineChartData(
                    response.data.data
                );
                this.chartIsLoading = false;
            })
            .catch(this.handleAxiosError);
    }
}
