






































import { Vue, Component, Prop } from "vue-property-decorator";
import TimeSeriesLineChartData from "@/value-objects/time-series-line-chart-data";
import { mdiArrowDown, mdiArrowUp } from "@mdi/js";
import currency from "@/filters/currency";

@Component
export default class ChartSubtitle extends Vue {
    @Prop({ required: true, type: Object }) chartData!: TimeSeriesLineChartData;
    upIcon: string = mdiArrowUp;
    downIcon: string = mdiArrowDown;

    toKCurrency(value: number): string {
        return currency(Math.round(value / 1000))
            .split(" ")
            .join("k ");
    }
}
